import React from 'react'
import logo from '../assets/images/logo.svg';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
        <h1>PythonVs.Com</h1>
        <h2>Comparison of Python to other programming languages.</h2>
    </header>
)

export default Header
