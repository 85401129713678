import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/pic01.jpg'
import pythonvs from '../assets/images/logo.svg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="PythonVs.Com - Python Versus The World." />
        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="main" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>What is Python?</h2>
                </header>
                <p>Python is one of the most popular programming languages out there. It's no. 3 in the <a href="https://www.tiobe.com/tiobe-index/">TIOBE</a> index, and no. 4 in the 2020 Stack Overflow Developer survey ranking of the most popular technologies. Its popularity is consistently rising, too.</p>

                <p>This isn't surprising, especially given the many areas in which Python works great.</p>

                <p>You can use it for web development, and the many Python libraries available will make the experience easier. If you dive into machine learning, you'll learn that Python is the most popular language for that purpose.</p>

                <p>Startups use it, because Python is scalable and reliable.</p>

                <p>Fintechs use it, because it's high performing and has ready-made solutions they can use.</p>

              <p>On top of all that, Python is a great programming language for beginners. It's quite easy to read, as it's not too far from actual English, and its syntax is clear.</p>

                <p>Finally, there are the frameworks you can use when you go with Python, which can help you build your product faster. You get to choose from Django, a secure framework focused on delivering business value, Flask, a microframework you can customize freely, and many, many more.</p>

                <ul className="actions">
                  <li>
                    <a href="https://stxnext.com/what-is-python-used-for/" className="button">
                      What is Python used for?
                    </a>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={pythonvs} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Python vs Other Programming Languages</h2>
            </header>
            <ul className="features">
              <li>
                <h3><Link to="/java">Python vs Java</Link></h3>
                <p>
              Python to Java comparison.
                </p>
              </li>
              <li>
                <h3><Link to="/r">Python vs R</Link></h3>
                <p>
                 Python to R comparison.
                </p>
              </li>
              <li>
                <h3><Link to="/cplusplus">Python vs C++</Link></h3>
                <p>
                  Python to C++ comparison.
                </p>
              </li>
              <li>
                  <h3><Link to="/nodejs">Python vs Node.js</Link></h3>
                <p>
                  Python to Node.js comparison.
                </p>
              </li>

              <li>
                  <h3><Link to="/php">Python vs PHP</Link></h3>
                <p>
                  Python to PHP comparison.
                </p>
              </li>
              <li>
                <h3><Link to="/go">Python vs GO</Link></h3>
                <p>
                  Python to GO comparison.
                </p>
              </li>
            </ul>

          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Python in numbers</h2>
              <p>
                Statistics of Python programming language based on <a href="https://www.tiobe.com/tiobe-index/">TIOBE index</a>, <a href="https://insights.stackoverflow.com/survey/2020">Stackoverflow Developer Survey 2020</a> and <a href="https://www.jetbrains.com/lp/devecosystem-2020/">The State of Developer Ecosystem 2020</a> by Jetbrains.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                Number
                <strong>3</strong> in TIOBE index
              </li>
              <li className="style2">
                Used by
                <strong>41,6%</strong>Developers
              </li>
              <li className="style3">
                Loved by
                <strong>66,7%</strong> Developers
              </li>
              <li className="style4"> Primary language
                <strong>31%</strong>  Developers
              </li>
              <li className="style5">
                Want to learn
                <strong>30%</strong> Developers              </li>
            </ul>


          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Need python development services?</h2>
              <p>

                Visit our site
                <br />
                to find out more about services we offer.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://www.stxnext.com/services/python-development/" className="button special">
                    Python Development Services
                  </a>
                </li>
                <li>
                  <a href="https://www.stxnext.com/portfolio/" className="button">
                    View our Portfolio
                  </a>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
